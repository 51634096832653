*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

main {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1rem; }

small {
  font-size: 80%; }

img {
  border-style: none; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
select {
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

html {
  --color-white: white;
  --color-black: #000;
  --color-default: #333;
  --color-default-text: var(--color-white);
  --color-primary: rgb(0, 112, 243);
  --color-primary-text: var(--color-white);
  --color-secondary: rgb(245, 0, 93);
  --color-secondary-text: var(--color-white);
  --color-success: #1ac567;
  --color-success-text: var(--color-white);
  --color-error: #ef5350;
  --color-error-text: var(--color-white);
  --color-warning: #ffb500;
  --color-warning-text: var(--color-black);
  --color-info: #42a5f5;
  --color-info-text: var(--color-white);
  --color-yellow: #ffeb3b;
  --color-yellow-text: var(--color-black);
  --color-overlay-dark: rgba(0, 0, 0, 0.75);
  --color-overlay-light: rgba(255, 255, 255, 0.75);
  --spacing: 8px;
  --border-width: 1px;
  --border-radius: 6px;
  --UiSize--1: 28px;
  --UiSize-0: 36px;
  --UiSize-1: 40px;
  --UiSize-2: 48px;
  --UiText-size--2: calc(1rem * 0.75);
  --UiText-size--1: calc(1rem * 0.875);
  --UiText-size-0: calc(1rem * 1);
  --UiText-size-1: calc(1rem * 1.125);
  --UiText-size-2: calc(1rem * 1.25);
  --UiText-size-3: calc(1rem * 1.5);
  --UiText-size-4: calc(1rem * 1.75);
  --UiText-size-5: calc(1rem * 2);
  --UiText-size-6: calc(1rem * 2.25);
  --UiText-size-7: calc(1rem * 2.5);
  --UiText-size-8: calc(1rem * 2.75);
  --UiText-size-9: calc(1rem * 3);
  --UiLayoutSidebar-width: 240px;
  --UiToolbar-z-index: 1000;
  --UiToolbar-height: calc(var(--UiSize-2));
  --UiToolbar-background-color: var(--color-background-body);
  --UiButton-border-radius: var(--border-radius);
  --UiButton-font-size--1: var(--UiText-size--1);
  --UiButton-font-size-0: var(--UiText-size-0);
  --UiButton-font-size-1: var(--UiText-size-1);
  --UiButton-font-size-2: var(--UiText-size-2);
  --UiInput-font-size: 16px;
  --UiOverlay-z-index: 2000;
  --UiOverlay-background-color: rgba(0, 0, 0, 0.95);
  --UiCard-border: 0;
  --UiCard-box-shadow: var(--box-shadow);
  --UiContainer-max-width: 1400px; }

html[dark] {
  --color-background-body: #18191a;
  --color-background-body-inverse: #eee;
  --color-background-paper: #242526;
  --color-primary: rgb(25, 137, 255);
  --color-text-primary: rgba(255, 255, 255, 0.87);
  --color-text-primary-inverse: #000;
  --color-text-secondary: rgba(255, 255, 255, 0.6);
  --color-text-disabled: rgba(127, 127, 127, 0.9);
  --color-divider: #333;
  --box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  --overlay-background-color: var(--color-overlay-dark);
  --UiButton-background-background-color-filled: rgb(25, 25, 25);
  background-color: var(--color-background-body);
  color: var(--color-text-primary); }

html:not([dark]) {
  --color-background-body: #fafafa;
  --color-background-body-inverse: #111;
  --color-background-paper: #fff;
  --color-secondary: #d81b60;
  --color-secondary-text: var(--color-white);
  --color-error: #d32f2f;
  --color-error-text: var(--color-white);
  --color-info: #0277bd;
  --color-info-text: var(--color-white);
  --color-text-primary: var(--color-black);
  --color-text-primary-inverse: var(--color-white);
  --color-text-secondary: rgb(60, 64, 67);
  --color-text-disabled: rgba(127, 127, 127, 0.9);
  --color-divider: rgba(127, 127, 127, 0.25);
  --box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.1);
  --overlay-background-color: var(--color-overlay-light);
  --UiButton-background-background-color-filled: #eee;
  background-color: var(--color-background-body);
  color: var(--color-text-primary); }

.UiAlert {
  background-color: var(--color-background-paper);
  border: var(--border-width) solid var(--color-divider);
  border-radius: var(--border-radius); }
  .UiAlert.color-primary {
    border-color: var(--color-primary);
    color: var(--color-primary);
    position: relative; }
  .UiAlert.color-secondary {
    border-color: var(--color-secondary);
    color: var(--color-secondary);
    position: relative; }
  .UiAlert.color-success {
    border-color: var(--color-success);
    color: var(--color-success);
    position: relative; }
  .UiAlert.color-error {
    border-color: var(--color-error);
    color: var(--color-error);
    position: relative; }
  .UiAlert.color-info {
    border-color: var(--color-info);
    color: var(--color-info);
    position: relative; }
  .UiAlert.color-warning {
    border-color: var(--color-warning);
    color: var(--color-warning);
    position: relative; }
  .UiAlert.color-yellow {
    border-color: var(--color-yellow);
    color: var(--color-yellow);
    position: relative; }
  .UiAlert.color-text-primary {
    border-color: var(--color-text-primary);
    color: var(--color-text-primary);
    position: relative; }
  .UiAlert.color-text-primary-inverse {
    border-color: var(--color-text-primary-inverse);
    color: var(--color-text-primary-inverse);
    position: relative; }
  .UiAlert.color-text-secondary {
    border-color: var(--color-text-secondary);
    color: var(--color-text-secondary);
    position: relative; }
  .UiAlert.color-divider {
    border-color: var(--color-divider);
    color: var(--color-divider);
    position: relative; }

.UiBox.padding-1 {
  padding: calc(var(--spacing) * 1); }

.UiBox.paddingTop-1 {
  padding-top: calc(var(--spacing) * 1); }

.UiBox.paddingRight-1 {
  padding-right: calc(var(--spacing) * 1); }

.UiBox.paddingBottom-1 {
  padding-bottom: calc(var(--spacing) * 1); }

.UiBox.paddingLeft-1 {
  padding-left: calc(var(--spacing) * 1); }

.UiBox.paddingHorizontal-1 {
  padding-left: calc(var(--spacing) * 1);
  padding-right: calc(var(--spacing) * 1); }

.UiBox.paddingVertical-1 {
  padding-top: calc(var(--spacing) * 1);
  padding-bottom: calc(var(--spacing) * 1); }

.UiBox.padding-2 {
  padding: calc(var(--spacing) * 2); }

.UiBox.paddingTop-2 {
  padding-top: calc(var(--spacing) * 2); }

.UiBox.paddingRight-2 {
  padding-right: calc(var(--spacing) * 2); }

.UiBox.paddingBottom-2 {
  padding-bottom: calc(var(--spacing) * 2); }

.UiBox.paddingLeft-2 {
  padding-left: calc(var(--spacing) * 2); }

.UiBox.paddingHorizontal-2 {
  padding-left: calc(var(--spacing) * 2);
  padding-right: calc(var(--spacing) * 2); }

.UiBox.paddingVertical-2 {
  padding-top: calc(var(--spacing) * 2);
  padding-bottom: calc(var(--spacing) * 2); }

.UiBox.padding-3 {
  padding: calc(var(--spacing) * 3); }

.UiBox.paddingTop-3 {
  padding-top: calc(var(--spacing) * 3); }

.UiBox.paddingRight-3 {
  padding-right: calc(var(--spacing) * 3); }

.UiBox.paddingBottom-3 {
  padding-bottom: calc(var(--spacing) * 3); }

.UiBox.paddingLeft-3 {
  padding-left: calc(var(--spacing) * 3); }

.UiBox.paddingHorizontal-3 {
  padding-left: calc(var(--spacing) * 3);
  padding-right: calc(var(--spacing) * 3); }

.UiBox.paddingVertical-3 {
  padding-top: calc(var(--spacing) * 3);
  padding-bottom: calc(var(--spacing) * 3); }

.UiBox.padding-4 {
  padding: calc(var(--spacing) * 4); }

.UiBox.paddingTop-4 {
  padding-top: calc(var(--spacing) * 4); }

.UiBox.paddingRight-4 {
  padding-right: calc(var(--spacing) * 4); }

.UiBox.paddingBottom-4 {
  padding-bottom: calc(var(--spacing) * 4); }

.UiBox.paddingLeft-4 {
  padding-left: calc(var(--spacing) * 4); }

.UiBox.paddingHorizontal-4 {
  padding-left: calc(var(--spacing) * 4);
  padding-right: calc(var(--spacing) * 4); }

.UiBox.paddingVertical-4 {
  padding-top: calc(var(--spacing) * 4);
  padding-bottom: calc(var(--spacing) * 4); }

.UiBox.padding-5 {
  padding: calc(var(--spacing) * 5); }

.UiBox.paddingTop-5 {
  padding-top: calc(var(--spacing) * 5); }

.UiBox.paddingRight-5 {
  padding-right: calc(var(--spacing) * 5); }

.UiBox.paddingBottom-5 {
  padding-bottom: calc(var(--spacing) * 5); }

.UiBox.paddingLeft-5 {
  padding-left: calc(var(--spacing) * 5); }

.UiBox.paddingHorizontal-5 {
  padding-left: calc(var(--spacing) * 5);
  padding-right: calc(var(--spacing) * 5); }

.UiBox.paddingVertical-5 {
  padding-top: calc(var(--spacing) * 5);
  padding-bottom: calc(var(--spacing) * 5); }

.UiBox.padding-6 {
  padding: calc(var(--spacing) * 6); }

.UiBox.paddingTop-6 {
  padding-top: calc(var(--spacing) * 6); }

.UiBox.paddingRight-6 {
  padding-right: calc(var(--spacing) * 6); }

.UiBox.paddingBottom-6 {
  padding-bottom: calc(var(--spacing) * 6); }

.UiBox.paddingLeft-6 {
  padding-left: calc(var(--spacing) * 6); }

.UiBox.paddingHorizontal-6 {
  padding-left: calc(var(--spacing) * 6);
  padding-right: calc(var(--spacing) * 6); }

.UiBox.paddingVertical-6 {
  padding-top: calc(var(--spacing) * 6);
  padding-bottom: calc(var(--spacing) * 6); }

.UiBox.padding-7 {
  padding: calc(var(--spacing) * 7); }

.UiBox.paddingTop-7 {
  padding-top: calc(var(--spacing) * 7); }

.UiBox.paddingRight-7 {
  padding-right: calc(var(--spacing) * 7); }

.UiBox.paddingBottom-7 {
  padding-bottom: calc(var(--spacing) * 7); }

.UiBox.paddingLeft-7 {
  padding-left: calc(var(--spacing) * 7); }

.UiBox.paddingHorizontal-7 {
  padding-left: calc(var(--spacing) * 7);
  padding-right: calc(var(--spacing) * 7); }

.UiBox.paddingVertical-7 {
  padding-top: calc(var(--spacing) * 7);
  padding-bottom: calc(var(--spacing) * 7); }

.UiBox.padding-8 {
  padding: calc(var(--spacing) * 8); }

.UiBox.paddingTop-8 {
  padding-top: calc(var(--spacing) * 8); }

.UiBox.paddingRight-8 {
  padding-right: calc(var(--spacing) * 8); }

.UiBox.paddingBottom-8 {
  padding-bottom: calc(var(--spacing) * 8); }

.UiBox.paddingLeft-8 {
  padding-left: calc(var(--spacing) * 8); }

.UiBox.paddingHorizontal-8 {
  padding-left: calc(var(--spacing) * 8);
  padding-right: calc(var(--spacing) * 8); }

.UiBox.paddingVertical-8 {
  padding-top: calc(var(--spacing) * 8);
  padding-bottom: calc(var(--spacing) * 8); }

.UiBox.border-true {
  border: var(--border-width) solid var(--color-divider); }

.UiBox.borderTop-true {
  border-top: var(--border-width) solid var(--color-divider); }

.UiBox.borderRight-true {
  border-right: var(--border-width) solid var(--color-divider); }

.UiBox.borderBottom-true {
  border-bottom: var(--border-width) solid var(--color-divider); }

.UiBox.borderLeft-true {
  border-left: var(--border-width) solid var(--color-divider); }

.UiBox.borderRadius-true {
  border-radius: var(--border-radius); }

.UiBox.boxShadow-true {
  box-shadow: var(--box-shadow); }

.UiBox.fullWidth-true {
  width: 100%; }

.UiBox.fullHeight-true {
  height: 100%; }

.UiBox.fullWidth-true {
  width: 100%; }

.UiBox.overflowAuto-true {
  overflow: auto; }

.UiButton {
  background-color: transparent;
  box-shadow: none;
  border: var(--border-width) solid transparent;
  border-radius: var(--UiButton-border-radius);
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 200ms, color 200ms, box-shadow 200ms, opacity 200ms;
  touch-action: manipulation;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0; }
  .UiButton.variant-outlined, .UiButton.variant-text {
    color: var(--color-text-primary); }
    .UiButton.variant-outlined.color-primary, .UiButton.variant-text.color-primary {
      color: var(--color-primary); }
      .UiButton.variant-outlined.color-primary:before, .UiButton.variant-text.color-primary:before {
        background-color: var(--color-primary); }
    .UiButton.variant-outlined.color-secondary, .UiButton.variant-text.color-secondary {
      color: var(--color-secondary); }
      .UiButton.variant-outlined.color-secondary:before, .UiButton.variant-text.color-secondary:before {
        background-color: var(--color-secondary); }
    .UiButton.variant-outlined.color-success, .UiButton.variant-text.color-success {
      color: var(--color-success); }
      .UiButton.variant-outlined.color-success:before, .UiButton.variant-text.color-success:before {
        background-color: var(--color-success); }
    .UiButton.variant-outlined.color-error, .UiButton.variant-text.color-error {
      color: var(--color-error); }
      .UiButton.variant-outlined.color-error:before, .UiButton.variant-text.color-error:before {
        background-color: var(--color-error); }
    .UiButton.variant-outlined.color-info, .UiButton.variant-text.color-info {
      color: var(--color-info); }
      .UiButton.variant-outlined.color-info:before, .UiButton.variant-text.color-info:before {
        background-color: var(--color-info); }
    .UiButton.variant-outlined.color-warning, .UiButton.variant-text.color-warning {
      color: var(--color-warning); }
      .UiButton.variant-outlined.color-warning:before, .UiButton.variant-text.color-warning:before {
        background-color: var(--color-warning); }
    .UiButton.variant-outlined.color-yellow, .UiButton.variant-text.color-yellow {
      color: var(--color-yellow); }
      .UiButton.variant-outlined.color-yellow:before, .UiButton.variant-text.color-yellow:before {
        background-color: var(--color-yellow); }
    .UiButton.variant-outlined.color-text-primary, .UiButton.variant-text.color-text-primary {
      color: var(--color-text-primary); }
      .UiButton.variant-outlined.color-text-primary:before, .UiButton.variant-text.color-text-primary:before {
        background-color: var(--color-text-primary); }
    .UiButton.variant-outlined.color-text-primary-inverse, .UiButton.variant-text.color-text-primary-inverse {
      color: var(--color-text-primary-inverse); }
      .UiButton.variant-outlined.color-text-primary-inverse:before, .UiButton.variant-text.color-text-primary-inverse:before {
        background-color: var(--color-text-primary-inverse); }
    .UiButton.variant-outlined.color-text-secondary, .UiButton.variant-text.color-text-secondary {
      color: var(--color-text-secondary); }
      .UiButton.variant-outlined.color-text-secondary:before, .UiButton.variant-text.color-text-secondary:before {
        background-color: var(--color-text-secondary); }
    .UiButton.variant-outlined.color-divider, .UiButton.variant-text.color-divider {
      color: var(--color-divider); }
      .UiButton.variant-outlined.color-divider:before, .UiButton.variant-text.color-divider:before {
        background-color: var(--color-divider); }
  .UiButton.variant-outlined {
    border: var(--border-width) solid var(--color-default); }
    .UiButton.variant-outlined.color-primary {
      border-color: var(--color-primary); }
    .UiButton.variant-outlined.color-secondary {
      border-color: var(--color-secondary); }
    .UiButton.variant-outlined.color-success {
      border-color: var(--color-success); }
    .UiButton.variant-outlined.color-error {
      border-color: var(--color-error); }
    .UiButton.variant-outlined.color-info {
      border-color: var(--color-info); }
    .UiButton.variant-outlined.color-warning {
      border-color: var(--color-warning); }
    .UiButton.variant-outlined.color-yellow {
      border-color: var(--color-yellow); }
    .UiButton.variant-outlined.color-text-primary {
      border-color: var(--color-text-primary); }
    .UiButton.variant-outlined.color-text-primary-inverse {
      border-color: var(--color-text-primary-inverse); }
    .UiButton.variant-outlined.color-text-secondary {
      border-color: var(--color-text-secondary); }
    .UiButton.variant-outlined.color-divider {
      border-color: var(--color-divider); }
  .UiButton.variant-filled {
    background-color: var(--UiButton-background-background-color-filled);
    color: var(--color-text-primary); }
    .UiButton.variant-filled.color-primary {
      background-color: var(--color-primary);
      color: var(--color-primary-text); }
    .UiButton.variant-filled.color-secondary {
      background-color: var(--color-secondary);
      color: var(--color-secondary-text); }
    .UiButton.variant-filled.color-success {
      background-color: var(--color-success);
      color: var(--color-success-text); }
    .UiButton.variant-filled.color-error {
      background-color: var(--color-error);
      color: var(--color-error-text); }
    .UiButton.variant-filled.color-info {
      background-color: var(--color-info);
      color: var(--color-info-text); }
    .UiButton.variant-filled.color-warning {
      background-color: var(--color-warning);
      color: var(--color-warning-text); }
    .UiButton.variant-filled.color-yellow {
      background-color: var(--color-yellow);
      color: var(--color-yellow-text); }
    .UiButton.variant-filled.color-text-primary {
      background-color: var(--color-text-primary);
      color: var(--color-text-primary-text); }
    .UiButton.variant-filled.color-text-primary-inverse {
      background-color: var(--color-text-primary-inverse);
      color: var(--color-text-primary-inverse-text); }
    .UiButton.variant-filled.color-text-secondary {
      background-color: var(--color-text-secondary);
      color: var(--color-text-secondary-text); }
    .UiButton.variant-filled.color-divider {
      background-color: var(--color-divider);
      color: var(--color-divider-text); }
  .UiButton.size--1 {
    font-weight: 700; }
  .UiButton.size-0 {
    font-weight: 600; }
  .UiButton.size-1 {
    font-weight: 500; }
  .UiButton.size-2 {
    font-weight: 400; }
  .UiButton.size--1 {
    font-size: var(--UiButton-font-size--1);
    min-height: var(--UiSize--1);
    padding-left: var(--UiButton-font-size--1);
    padding-right: var(--UiButton-font-size--1); }
    .UiButton.size--1.iconCircle-true {
      height: var(--UiSize--1);
      width: var(--UiSize--1);
      min-width: var(--UiSize--1);
      min-height: var(--UiSize--1); }
  .UiButton.size-0 {
    font-size: var(--UiButton-font-size-0);
    min-height: var(--UiSize-0);
    padding-left: var(--UiButton-font-size-0);
    padding-right: var(--UiButton-font-size-0); }
    .UiButton.size-0.iconCircle-true {
      height: var(--UiSize-0);
      width: var(--UiSize-0);
      min-width: var(--UiSize-0);
      min-height: var(--UiSize-0); }
  .UiButton.size-1 {
    font-size: var(--UiButton-font-size-1);
    min-height: var(--UiSize-1);
    padding-left: var(--UiButton-font-size-1);
    padding-right: var(--UiButton-font-size-1); }
    .UiButton.size-1.iconCircle-true {
      height: var(--UiSize-1);
      width: var(--UiSize-1);
      min-width: var(--UiSize-1);
      min-height: var(--UiSize-1); }
  .UiButton.size-2 {
    font-size: var(--UiButton-font-size-2);
    min-height: var(--UiSize-2);
    padding-left: var(--UiButton-font-size-2);
    padding-right: var(--UiButton-font-size-2); }
    .UiButton.size-2.iconCircle-true {
      height: var(--UiSize-2);
      width: var(--UiSize-2);
      min-width: var(--UiSize-2);
      min-height: var(--UiSize-2); }
  .UiButton > .button-content {
    transition: opacity 200ms;
    position: relative; }
  .UiButton.disabled-true {
    opacity: 0.5; }
  .UiButton:focus {
    outline: none; }
  .UiButton:before {
    background-color: white;
    border-radius: inherit;
    content: '';
    position: absolute;
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    opacity: 0;
    transition: opacity 200ms; }
  .UiButton:not(.disabled-true):focus:before, .UiButton:not(.disabled-true):hover:before {
    opacity: 0.1; }
  .UiButton.direction-column {
    flex-direction: column;
    padding-top: var(--spacing);
    padding-bottom: var(--spacing); }
  .UiButton.isLoading-true .button-content {
    opacity: 0.25; }
  .UiButton.fullWidth-true {
    width: 100%; }
  .UiButton.noWrap-true {
    white-space: nowrap; }
  .UiButton .loader {
    position: absolute; }
  .UiButton.iconCircle-true, .UiButton.iconCircle-true:before {
    border-radius: 100%; }
  .UiButton.icon-true {
    padding: 0; }
  .UiButton:not(.icon-true).UiIconMaterial {
    margin-right: 4px;
    font-size: 20px; }

.UiCard {
  background-color: var(--color-background-paper);
  position: relative;
  transition: box-shadow 200ms;
  border: var(--UiCard-border);
  box-shadow: var(--UiCard-box-shadow); }
  .UiCard.clickable {
    cursor: pointer; }
  .UiCard .card-header {
    border-bottom: var(--border-width) solid var(--color-divider); }
  .UiCard .card-footer {
    border-top: var(--border-width) solid var(--color-divider); }

.UiContainer {
  max-width: var(--UiContainer-max-width);
  margin: 0 auto; }

body.hide-overflow {
  overflow: hidden; }

.UiDialog {
  overflow-y: auto;
  animation-duration: 200ms; }
  .UiDialog,
  .UiDialog > .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
  .UiDialog,
  .UiDialog > .overlay,
  .UiDialog > .content > .inner {
    z-index: 2000; }
  .UiDialog > .overlay {
    background-color: var(--overlay-background-color); }
  .UiDialog > .content {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1 0 auto;
    padding: calc(var(--spacing) * 2);
    position: relative; }
    .UiDialog > .content > .inner {
      max-width: 512px;
      width: 100%; }

.UiFlex {
  display: flex; }
  .UiFlex.flexChildren-true > * {
    flex: 1; }
  .UiFlex.wrap-nowrap {
    flex-wrap: nowrap; }
  .UiFlex.wrap-wrap {
    flex-wrap: wrap; }
  .UiFlex.direction-row {
    flex-direction: row; }
  .UiFlex.direction-column {
    flex-direction: column; }
  .UiFlex.alignItems-flex-start {
    align-items: flex-start; }
  .UiFlex.alignItems-center {
    align-items: center; }
  .UiFlex.alignItems-flex-end {
    align-items: flex-end; }
  .UiFlex.alignItems-stretch {
    align-items: stretch; }
  .UiFlex.justifyContent-flex-start {
    justify-content: flex-start; }
  .UiFlex.justifyContent-center {
    justify-content: center; }
  .UiFlex.justifyContent-flex-end {
    justify-content: flex-end; }
  .UiFlex.justifyContent-space-between {
    justify-content: space-between; }
  .UiFlex.direction-row {
    flex-direction: row; }
    .UiFlex.direction-row.gap-1 > *:not(:first-child) {
      margin-left: calc(var(--spacing) * 1); }
    .UiFlex.direction-row.gap-2 > *:not(:first-child) {
      margin-left: calc(var(--spacing) * 2); }
    .UiFlex.direction-row.gap-3 > *:not(:first-child) {
      margin-left: calc(var(--spacing) * 3); }
    .UiFlex.direction-row.gap-4 > *:not(:first-child) {
      margin-left: calc(var(--spacing) * 4); }
    .UiFlex.direction-row.gap-5 > *:not(:first-child) {
      margin-left: calc(var(--spacing) * 5); }
    .UiFlex.direction-row.gap-6 > *:not(:first-child) {
      margin-left: calc(var(--spacing) * 6); }
    .UiFlex.direction-row.gap-7 > *:not(:first-child) {
      margin-left: calc(var(--spacing) * 7); }
    .UiFlex.direction-row.gap-8 > *:not(:first-child) {
      margin-left: calc(var(--spacing) * 8); }
  .UiFlex.direction-column {
    flex-direction: column; }
    .UiFlex.direction-column.gap-1 > *:not(:first-child) {
      margin-top: calc(var(--spacing) * 1); }
    .UiFlex.direction-column.gap-2 > *:not(:first-child) {
      margin-top: calc(var(--spacing) * 2); }
    .UiFlex.direction-column.gap-3 > *:not(:first-child) {
      margin-top: calc(var(--spacing) * 3); }
    .UiFlex.direction-column.gap-4 > *:not(:first-child) {
      margin-top: calc(var(--spacing) * 4); }
    .UiFlex.direction-column.gap-5 > *:not(:first-child) {
      margin-top: calc(var(--spacing) * 5); }
    .UiFlex.direction-column.gap-6 > *:not(:first-child) {
      margin-top: calc(var(--spacing) * 6); }
    .UiFlex.direction-column.gap-7 > *:not(:first-child) {
      margin-top: calc(var(--spacing) * 7); }
    .UiFlex.direction-column.gap-8 > *:not(:first-child) {
      margin-top: calc(var(--spacing) * 8); }

.UiGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  position: relative; }
  .UiGrid.gap-1 {
    grid-gap: calc(var(--spacing) * 1); }
  .UiGrid.spacing-1 {
    grid-gap: calc(var(--spacing) * 1); }
  .UiGrid.outerSpacing-1 {
    padding: calc(var(--spacing) * 1); }
  .UiGrid.gap-2 {
    grid-gap: calc(var(--spacing) * 2); }
  .UiGrid.spacing-2 {
    grid-gap: calc(var(--spacing) * 2); }
  .UiGrid.outerSpacing-2 {
    padding: calc(var(--spacing) * 2); }
  .UiGrid.gap-3 {
    grid-gap: calc(var(--spacing) * 3); }
  .UiGrid.spacing-3 {
    grid-gap: calc(var(--spacing) * 3); }
  .UiGrid.outerSpacing-3 {
    padding: calc(var(--spacing) * 3); }
  .UiGrid.gap-4 {
    grid-gap: calc(var(--spacing) * 4); }
  .UiGrid.spacing-4 {
    grid-gap: calc(var(--spacing) * 4); }
  .UiGrid.outerSpacing-4 {
    padding: calc(var(--spacing) * 4); }
  .UiGrid.gap-5 {
    grid-gap: calc(var(--spacing) * 5); }
  .UiGrid.spacing-5 {
    grid-gap: calc(var(--spacing) * 5); }
  .UiGrid.outerSpacing-5 {
    padding: calc(var(--spacing) * 5); }
  .UiGrid.gap-6 {
    grid-gap: calc(var(--spacing) * 6); }
  .UiGrid.spacing-6 {
    grid-gap: calc(var(--spacing) * 6); }
  .UiGrid.outerSpacing-6 {
    padding: calc(var(--spacing) * 6); }
  .UiGrid.gap-7 {
    grid-gap: calc(var(--spacing) * 7); }
  .UiGrid.spacing-7 {
    grid-gap: calc(var(--spacing) * 7); }
  .UiGrid.outerSpacing-7 {
    padding: calc(var(--spacing) * 7); }
  .UiGrid.gap-8 {
    grid-gap: calc(var(--spacing) * 8); }
  .UiGrid.spacing-8 {
    grid-gap: calc(var(--spacing) * 8); }
  .UiGrid.outerSpacing-8 {
    padding: calc(var(--spacing) * 8); }
  .UiGrid.gap-9 {
    grid-gap: calc(var(--spacing) * 9); }
  .UiGrid.spacing-9 {
    grid-gap: calc(var(--spacing) * 9); }
  .UiGrid.outerSpacing-9 {
    padding: calc(var(--spacing) * 9); }
  .UiGrid.gap-10 {
    grid-gap: calc(var(--spacing) * 10); }
  .UiGrid.spacing-10 {
    grid-gap: calc(var(--spacing) * 10); }
  .UiGrid.outerSpacing-10 {
    padding: calc(var(--spacing) * 10); }
  .UiGrid.gap-11 {
    grid-gap: calc(var(--spacing) * 11); }
  .UiGrid.spacing-11 {
    grid-gap: calc(var(--spacing) * 11); }
  .UiGrid.outerSpacing-11 {
    padding: calc(var(--spacing) * 11); }
  .UiGrid.gap-12 {
    grid-gap: calc(var(--spacing) * 12); }
  .UiGrid.spacing-12 {
    grid-gap: calc(var(--spacing) * 12); }
  .UiGrid.outerSpacing-12 {
    padding: calc(var(--spacing) * 12); }
  .UiGrid.gap-13 {
    grid-gap: calc(var(--spacing) * 13); }
  .UiGrid.spacing-13 {
    grid-gap: calc(var(--spacing) * 13); }
  .UiGrid.outerSpacing-13 {
    padding: calc(var(--spacing) * 13); }
  .UiGrid.gap-14 {
    grid-gap: calc(var(--spacing) * 14); }
  .UiGrid.spacing-14 {
    grid-gap: calc(var(--spacing) * 14); }
  .UiGrid.outerSpacing-14 {
    padding: calc(var(--spacing) * 14); }
  .UiGrid.gap-15 {
    grid-gap: calc(var(--spacing) * 15); }
  .UiGrid.spacing-15 {
    grid-gap: calc(var(--spacing) * 15); }
  .UiGrid.outerSpacing-15 {
    padding: calc(var(--spacing) * 15); }
  .UiGrid.gap-16 {
    grid-gap: calc(var(--spacing) * 16); }
  .UiGrid.spacing-16 {
    grid-gap: calc(var(--spacing) * 16); }
  .UiGrid.outerSpacing-16 {
    padding: calc(var(--spacing) * 16); }

.UiImage {
  transition: opacity 500ms;
  opacity: 0; }
  .UiImage.circle-true {
    border-radius: 100%; }
  .UiImage.type-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .UiImage.isLoaded-true {
    opacity: 1; }

.UiInput {
  border-radius: var(--border-radius);
  position: relative;
  display: flex;
  align-items: center;
  font-size: var(--UiInput-font-size);
  min-width: 64px; }
  .UiInput:not(.noBorder) {
    box-shadow: 0 0 0 var(--border-width) var(--color-divider); }
  .UiInput .label {
    animation-duration: 200ms;
    position: absolute;
    top: -6px;
    left: 8px;
    font-size: 0.625rem;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--color-text-secondary);
    background-color: var(--color-background-body);
    padding: 0 4px;
    z-index: 10;
    border-radius: var(--border-radius); }
  .UiInput select,
  .UiInput input,
  .UiInput textarea {
    color: inherit;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    outline: none;
    font-family: inherit;
    height: 100%;
    padding-left: 8px;
    flex: 1;
    z-index: 1;
    line-height: normal;
    max-width: 100%; }
    .UiInput select::placeholder,
    .UiInput input::placeholder,
    .UiInput textarea::placeholder {
      height: inherit;
      line-height: inherit; }
  .UiInput textarea {
    max-width: 100%; }
  .UiInput select {
    background-color: transparent;
    appearance: none; }
    .UiInput select option {
      background-color: var(--color-background-paper);
      color: var(--color-text-primary); }
  .UiInput.type-checkbox {
    justify-content: center;
    box-shadow: none;
    touch-action: manipulation;
    user-select: none;
    cursor: pointer; }
    .UiInput.type-checkbox input {
      display: none; }
  .UiInput .adornment-start,
  .UiInput .adornment-end {
    display: flex;
    align-items: center; }
  .UiInput .select-icon {
    position: absolute;
    right: 8px; }
  .UiInput.flex {
    flex: 1; }
  .UiInput.disabled {
    opacity: 0.5; }
  .UiInput.fullWidth {
    width: 100%; }
  .UiInput.readOnly {
    background-color: transparent;
    border: none;
    box-shadow: none; }
    .UiInput.readOnly select,
    .UiInput.readOnly input,
    .UiInput.readOnly textarea {
      background-color: transparent; }
  .UiInput.type-textarea {
    padding: 8px 4px; }
    .UiInput.type-textarea textarea {
      min-height: 48px;
      width: 100% !important; }
  .UiInput:not(.type-textarea).size--1 {
    height: var(--UiSize--1); }
  .UiInput:not(.type-textarea).size-0 {
    height: var(--UiSize-0); }
  .UiInput:not(.type-textarea).size-1 {
    height: var(--UiSize-1); }
  .UiInput:not(.type-textarea).size-2 {
    height: var(--UiSize-2); }

.UiLoader.color-primary > div {
  background-color: var(--color-primary); }

.UiLoader.color-secondary > div {
  background-color: var(--color-secondary); }

.UiLoader.color-success > div {
  background-color: var(--color-success); }

.UiLoader.color-error > div {
  background-color: var(--color-error); }

.UiLoader.color-info > div {
  background-color: var(--color-info); }

.UiLoader.color-warning > div {
  background-color: var(--color-warning); }

.UiLoader.color-yellow > div {
  background-color: var(--color-yellow); }

.UiLoader.color-text-primary > div {
  background-color: var(--color-text-primary); }

.UiLoader.color-text-primary-inverse > div {
  background-color: var(--color-text-primary-inverse); }

.UiLoader.color-text-secondary > div {
  background-color: var(--color-text-secondary); }

.UiLoader.color-divider > div {
  background-color: var(--color-divider); }

.UiLoader > div {
  border-radius: 100%;
  margin: 0;
  animation-fill-mode: both;
  display: inline-block;
  animation: ball-beat 0.7s 0s infinite linear; }
  .UiLoader > div:not(:first-child) {
    margin-left: 4px; }
  .UiLoader > div:nth-child(2n-1) {
    animation-delay: -0.35s !important; }

@keyframes ball-beat {
  50% {
    opacity: 0.2;
    transform: scale(0.75); }
  100% {
    opacity: 1;
    transform: scale(1); } }

.UiOverlay,
.UiOverlay .UiOverlay-background,
.UiOverlay .UiOverlay-content {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: var(--UiOverlay-z-index); }

.UiOverlay .UiOverlay-background {
  background-color: rgba(0, 0, 0, 0.85); }

.UiOverlay .UiOverlay-content {
  background-color: var(--color-background-body);
  z-index: calc(1 + var(--UiOverlay-z-index)); }
  .UiOverlay .UiOverlay-content.position-bottom {
    top: auto;
    bottom: 0; }
  .UiOverlay .UiOverlay-content.transparent-true {
    background-color: transparent; }

.UiPadding.negative-1 {
  margin-left: calc(0px - var(--spacing) * 1);
  margin-right: calc(0px - var(--spacing) * 1); }

.UiPadding.negative-2 {
  margin-left: calc(0px - var(--spacing) * 2);
  margin-right: calc(0px - var(--spacing) * 2); }

.UiPadding.negative-3 {
  margin-left: calc(0px - var(--spacing) * 3);
  margin-right: calc(0px - var(--spacing) * 3); }

.UiPadding.negative-4 {
  margin-left: calc(0px - var(--spacing) * 4);
  margin-right: calc(0px - var(--spacing) * 4); }

.UiPadding.outer-1 {
  padding: calc(var(--spacing) * 1); }

.UiPadding.outer-2 {
  padding: calc(var(--spacing) * 2); }

.UiPadding.outer-3 {
  padding: calc(var(--spacing) * 3); }

.UiPadding.outer-4 {
  padding: calc(var(--spacing) * 4); }

.UiPadding.outer-5 {
  padding: calc(var(--spacing) * 5); }

.UiPadding.outer-6 {
  padding: calc(var(--spacing) * 6); }

.UiPadding.outer-7 {
  padding: calc(var(--spacing) * 7); }

.UiPadding.outer-8 {
  padding: calc(var(--spacing) * 8); }

.UiPadding.outer-9 {
  padding: calc(var(--spacing) * 9); }

.UiPadding.outer-10 {
  padding: calc(var(--spacing) * 10); }

.UiPadding.inner-1 {
  padding: calc(var(--spacing) * 1); }

.UiPadding.inner-2 {
  padding: calc(var(--spacing) * 2); }

.UiPadding.inner-3 {
  padding: calc(var(--spacing) * 3); }

.UiPadding.inner-4 {
  padding: calc(var(--spacing) * 4); }

.UiPadding.inner-5 {
  padding: calc(var(--spacing) * 5); }

.UiPadding.inner-6 {
  padding: calc(var(--spacing) * 6); }

.UiPadding.inner-7 {
  padding: calc(var(--spacing) * 7); }

.UiPadding.inner-8 {
  padding: calc(var(--spacing) * 8); }

.UiPadding.inner-9 {
  padding: calc(var(--spacing) * 9); }

.UiPadding.inner-10 {
  padding: calc(var(--spacing) * 10); }

.UiPadding.horizontal-1 {
  padding-left: calc(var(--spacing) * 1);
  padding-right: calc(var(--spacing) * 1); }

.UiPadding.vertical-1 {
  padding-top: calc(var(--spacing) * 1);
  padding-bottom: calc(var(--spacing) * 1); }

.UiPadding.top-1 {
  padding-top: calc(var(--spacing) * 1); }

.UiPadding.bottom-1 {
  padding-bottom: calc(var(--spacing) * 1); }

.UiPadding.left-1 {
  padding-left: calc(var(--spacing) * 1); }

.UiPadding.horizontal-2 {
  padding-left: calc(var(--spacing) * 2);
  padding-right: calc(var(--spacing) * 2); }

.UiPadding.vertical-2 {
  padding-top: calc(var(--spacing) * 2);
  padding-bottom: calc(var(--spacing) * 2); }

.UiPadding.top-2 {
  padding-top: calc(var(--spacing) * 2); }

.UiPadding.bottom-2 {
  padding-bottom: calc(var(--spacing) * 2); }

.UiPadding.left-2 {
  padding-left: calc(var(--spacing) * 2); }

.UiPadding.horizontal-3 {
  padding-left: calc(var(--spacing) * 3);
  padding-right: calc(var(--spacing) * 3); }

.UiPadding.vertical-3 {
  padding-top: calc(var(--spacing) * 3);
  padding-bottom: calc(var(--spacing) * 3); }

.UiPadding.top-3 {
  padding-top: calc(var(--spacing) * 3); }

.UiPadding.bottom-3 {
  padding-bottom: calc(var(--spacing) * 3); }

.UiPadding.left-3 {
  padding-left: calc(var(--spacing) * 3); }

.UiPadding.horizontal-4 {
  padding-left: calc(var(--spacing) * 4);
  padding-right: calc(var(--spacing) * 4); }

.UiPadding.vertical-4 {
  padding-top: calc(var(--spacing) * 4);
  padding-bottom: calc(var(--spacing) * 4); }

.UiPadding.top-4 {
  padding-top: calc(var(--spacing) * 4); }

.UiPadding.bottom-4 {
  padding-bottom: calc(var(--spacing) * 4); }

.UiPadding.left-4 {
  padding-left: calc(var(--spacing) * 4); }

.UiPadding.horizontal-5 {
  padding-left: calc(var(--spacing) * 5);
  padding-right: calc(var(--spacing) * 5); }

.UiPadding.vertical-5 {
  padding-top: calc(var(--spacing) * 5);
  padding-bottom: calc(var(--spacing) * 5); }

.UiPadding.top-5 {
  padding-top: calc(var(--spacing) * 5); }

.UiPadding.bottom-5 {
  padding-bottom: calc(var(--spacing) * 5); }

.UiPadding.left-5 {
  padding-left: calc(var(--spacing) * 5); }

.UiPadding.horizontal-6 {
  padding-left: calc(var(--spacing) * 6);
  padding-right: calc(var(--spacing) * 6); }

.UiPadding.vertical-6 {
  padding-top: calc(var(--spacing) * 6);
  padding-bottom: calc(var(--spacing) * 6); }

.UiPadding.top-6 {
  padding-top: calc(var(--spacing) * 6); }

.UiPadding.bottom-6 {
  padding-bottom: calc(var(--spacing) * 6); }

.UiPadding.left-6 {
  padding-left: calc(var(--spacing) * 6); }

.UiPadding.horizontal-7 {
  padding-left: calc(var(--spacing) * 7);
  padding-right: calc(var(--spacing) * 7); }

.UiPadding.vertical-7 {
  padding-top: calc(var(--spacing) * 7);
  padding-bottom: calc(var(--spacing) * 7); }

.UiPadding.top-7 {
  padding-top: calc(var(--spacing) * 7); }

.UiPadding.bottom-7 {
  padding-bottom: calc(var(--spacing) * 7); }

.UiPadding.left-7 {
  padding-left: calc(var(--spacing) * 7); }

.UiPadding.horizontal-8 {
  padding-left: calc(var(--spacing) * 8);
  padding-right: calc(var(--spacing) * 8); }

.UiPadding.vertical-8 {
  padding-top: calc(var(--spacing) * 8);
  padding-bottom: calc(var(--spacing) * 8); }

.UiPadding.top-8 {
  padding-top: calc(var(--spacing) * 8); }

.UiPadding.bottom-8 {
  padding-bottom: calc(var(--spacing) * 8); }

.UiPadding.left-8 {
  padding-left: calc(var(--spacing) * 8); }

.UiPadding.horizontal-9 {
  padding-left: calc(var(--spacing) * 9);
  padding-right: calc(var(--spacing) * 9); }

.UiPadding.vertical-9 {
  padding-top: calc(var(--spacing) * 9);
  padding-bottom: calc(var(--spacing) * 9); }

.UiPadding.top-9 {
  padding-top: calc(var(--spacing) * 9); }

.UiPadding.bottom-9 {
  padding-bottom: calc(var(--spacing) * 9); }

.UiPadding.left-9 {
  padding-left: calc(var(--spacing) * 9); }

.UiPadding.horizontal-10 {
  padding-left: calc(var(--spacing) * 10);
  padding-right: calc(var(--spacing) * 10); }

.UiPadding.vertical-10 {
  padding-top: calc(var(--spacing) * 10);
  padding-bottom: calc(var(--spacing) * 10); }

.UiPadding.top-10 {
  padding-top: calc(var(--spacing) * 10); }

.UiPadding.bottom-10 {
  padding-bottom: calc(var(--spacing) * 10); }

.UiPadding.left-10 {
  padding-left: calc(var(--spacing) * 10); }

.UiPills {
  overflow: auto; }
  .UiPills .UiButton.UiButton {
    text-transform: uppercase;
    font-weight: 500;
    color: var(--color-text-primary);
    padding: 0 calc(var(--spacing) * 2); }
    .UiPills .UiButton.UiButton.active {
      color: var(--color-primary); }

.UiSplashScreen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.UiTable {
  overflow: auto; }
  .UiTable table {
    width: 100%;
    border-spacing: 0;
    font-weight: inherit; }
    .UiTable table thead {
      background-color: rgba(127, 127, 127, 0.1);
      font-weight: bold;
      color: var(--color-text-secondary);
      text-transform: uppercase;
      font-size: 0.625rem; }
    .UiTable table tr.divider {
      background-color: var(--color-divider); }
    .UiTable table tbody tr:not(:first-child) {
      box-shadow: 0 calc(-1 * var(--border-width)) 0 0 rgba(127, 127, 127, 0.1); }
    .UiTable table tfoot {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.625rem; }
    .UiTable table td {
      padding: 8px; }
    .UiTable table td .icon-container {
      border-radius: var(--border-radius);
      height: 32px;
      width: 32px;
      padding: 4px; }

.UiText.size--2 {
  font-size: var(--UiText-size--2); }

.UiText.size--1 {
  font-size: var(--UiText-size--1); }

.UiText.size-0 {
  font-size: var(--UiText-size-0); }

.UiText.size-1 {
  font-size: var(--UiText-size-1); }

.UiText.size-2 {
  font-size: var(--UiText-size-2); }

.UiText.size-3 {
  font-size: var(--UiText-size-3); }

.UiText.size-4 {
  font-size: var(--UiText-size-4); }

.UiText.size-5 {
  font-size: var(--UiText-size-5); }

.UiText.size-6 {
  font-size: var(--UiText-size-6); }

.UiText.size-7 {
  font-size: var(--UiText-size-7); }

.UiText.size-8 {
  font-size: var(--UiText-size-8); }

.UiText.size-9 {
  font-size: var(--UiText-size-9); }

.UiText.size-3 {
  font-weight: 300; }

.UiText.size-4 {
  font-weight: 300; }

.UiText.size-5 {
  font-weight: 300; }

.UiText.size-6 {
  font-weight: 300; }

.UiText.size-7 {
  font-weight: 200; }

.UiText.size-8 {
  font-weight: 200; }

.UiText.size-9 {
  font-weight: 200; }

.UiText.align-center {
  text-align: center; }

.UiText.text-right {
  text-align: right; }

.UiText.weight--1 {
  font-weight: 300; }

.UiText.weight-0 {
  font-weight: 400; }

.UiText.weight-1 {
  font-weight: 500; }

.UiText.weight-2 {
  font-weight: 700; }

.UiText.transform-uppercase {
  text-transform: uppercase; }

.UiText.noWrap-true {
  white-space: nowrap; }

.UiText.color-primary {
  color: var(--color-primary); }

.UiText.color-secondary {
  color: var(--color-secondary); }

.UiText.color-success {
  color: var(--color-success); }

.UiText.color-error {
  color: var(--color-error); }

.UiText.color-info {
  color: var(--color-info); }

.UiText.color-warning {
  color: var(--color-warning); }

.UiText.color-yellow {
  color: var(--color-yellow); }

.UiText.color-text-primary {
  color: var(--color-text-primary); }

.UiText.color-text-primary-inverse {
  color: var(--color-text-primary-inverse); }

.UiText.color-text-secondary {
  color: var(--color-text-secondary); }

.UiText.color-divider {
  color: var(--color-divider); }

.UiToggle {
  background-color: var(--color-background-body);
  padding: 0.25rem;
  position: relative;
  border-radius: var(--border-radius);
  border: var(--border-width) solid var(--color-divider); }
  .UiToggle > .content {
    border-radius: var(--border-radius);
    position: relative; }
  .UiToggle button {
    height: 100%; }
    .UiToggle button.active {
      color: white; }
    .UiToggle button:not(.indicator) {
      z-index: 1; }
      .UiToggle button:not(.indicator):before {
        display: none; }
  .UiToggle .indicator {
    background-color: var(--color-secondary);
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 200ms; }
    .UiToggle .indicator.buttons-1 {
      width: calc(100% / 1); }
    .UiToggle .indicator.buttons-2 {
      width: calc(100% / 2); }
    .UiToggle .indicator.buttons-3 {
      width: calc(100% / 3); }
    .UiToggle .indicator.buttons-4 {
      width: calc(100% / 4); }
    .UiToggle .indicator.buttons-5 {
      width: calc(100% / 5); }
    .UiToggle .indicator.buttons-6 {
      width: calc(100% / 6); }
    .UiToggle .indicator.buttons-7 {
      width: calc(100% / 7); }
    .UiToggle .indicator.buttons-8 {
      width: calc(100% / 8); }
    .UiToggle .indicator.buttons-9 {
      width: calc(100% / 9); }
    .UiToggle .indicator.buttons-10 {
      width: calc(100% / 10); }
    .UiToggle .indicator.active-0 {
      transform: translateX(0%); }
    .UiToggle .indicator.active-1 {
      transform: translateX(100%); }
    .UiToggle .indicator.active-2 {
      transform: translateX(200%); }
    .UiToggle .indicator.active-3 {
      transform: translateX(300%); }
    .UiToggle .indicator.active-4 {
      transform: translateX(400%); }
    .UiToggle .indicator.active-5 {
      transform: translateX(500%); }
    .UiToggle .indicator.active-6 {
      transform: translateX(600%); }
    .UiToggle .indicator.active-7 {
      transform: translateX(700%); }
    .UiToggle .indicator.active-8 {
      transform: translateX(800%); }
    .UiToggle .indicator.active-9 {
      transform: translateX(900%); }
    .UiToggle .indicator.active-10 {
      transform: translateX(1000%); }

.UiToolbar .UiToolbar-container {
  background-color: var(--UiToolbar-background-color);
  box-shadow: 0 0 0 var(--border-width) var(--color-divider);
  position: fixed;
  left: 0;
  width: 100%;
  z-index: var(--UiToolbar-z-index); }
  .UiToolbar .UiToolbar-container.position-top {
    top: 0; }
  .UiToolbar .UiToolbar-container.position-bottom {
    bottom: 0; }

.UiToolbar .UiToolbar-content {
  background-color: var(--UiToolbar-background-color); }

.UiToolbar .UiToolbar-content,
.UiToolbar .UiToolbar-placeholder {
  height: var(--UiToolbar-height); }

.UiButtonCheckbox {
  padding-left: var(--spacing) !important;
  justify-content: flex-start; }
  .UiButtonCheckbox .icon {
    display: flex; }
  .UiButtonCheckbox:not(.checked) .icon {
    color: var(--color-text-secondary); }

.UiButtonNav .nav {
  position: relative; }
  .UiButtonNav .nav:before {
    content: '';
    background-color: var(--color-divider);
    height: var(--border-width);
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: 0.5; }
  .UiButtonNav .nav a {
    border-radius: 0;
    border-bottom: var(--border-width) solid transparent;
    transition: border 200ms, font-weight 200ms; }
    .UiButtonNav .nav a:before {
      border-radius: inherit; }
    .UiButtonNav .nav a.active {
      color: var(--color-primary);
      border-bottom-color: var(--color-primary); }

.UiExampleImage .card {
  height: 200px;
  overflow: hidden;
  transform: translateZ(0); }

.UiExampleImage .hero {
  height: 400px;
  overflow: hidden;
  transform: translateZ(0); }
  .UiExampleImage .hero .hero-content {
    background-color: var(--color-overlay-dark);
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    color: var(--color-white); }

.UiIconMaterial {
  color: inherit; }
  .UiIconMaterial.size-1 {
    font-size: 1px; }
  .UiIconMaterial.size-2 {
    font-size: 2px; }
  .UiIconMaterial.size-3 {
    font-size: 3px; }
  .UiIconMaterial.size-4 {
    font-size: 4px; }
  .UiIconMaterial.size-5 {
    font-size: 5px; }
  .UiIconMaterial.size-6 {
    font-size: 6px; }
  .UiIconMaterial.size-7 {
    font-size: 7px; }
  .UiIconMaterial.size-8 {
    font-size: 8px; }
  .UiIconMaterial.size-9 {
    font-size: 9px; }
  .UiIconMaterial.size-10 {
    font-size: 10px; }
  .UiIconMaterial.size-11 {
    font-size: 11px; }
  .UiIconMaterial.size-12 {
    font-size: 12px; }
  .UiIconMaterial.size-13 {
    font-size: 13px; }
  .UiIconMaterial.size-14 {
    font-size: 14px; }
  .UiIconMaterial.size-15 {
    font-size: 15px; }
  .UiIconMaterial.size-16 {
    font-size: 16px; }
  .UiIconMaterial.size-17 {
    font-size: 17px; }
  .UiIconMaterial.size-18 {
    font-size: 18px; }
  .UiIconMaterial.size-19 {
    font-size: 19px; }
  .UiIconMaterial.size-20 {
    font-size: 20px; }
  .UiIconMaterial.size-21 {
    font-size: 21px; }
  .UiIconMaterial.size-22 {
    font-size: 22px; }
  .UiIconMaterial.size-23 {
    font-size: 23px; }
  .UiIconMaterial.size-24 {
    font-size: 24px; }

.UiInputEditable {
  display: flex;
  align-items: center;
  position: relative; }
  .UiInputEditable > .value {
    padding: 0 var(--spacing); }
  .UiInputEditable .actions {
    background-color: var(--color-background-body);
    border-radius: 40px;
    z-index: 100; }
    .UiInputEditable .actions i {
      opacity: 0.5; }
  .UiInputEditable.size--1 {
    height: var(--UiSize--1); }
  .UiInputEditable.size-0 {
    height: var(--UiSize-0); }
  .UiInputEditable.size-1 {
    height: var(--UiSize-1); }
  .UiInputEditable.size-2 {
    height: var(--UiSize-2); }

.UiInputPhone {
  border: var(--border-width) solid var(--color-divider);
  border-radius: var(--border-radius);
  width: 100%; }
  .UiInputPhone .UiInput:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .UiInputPhone .UiInput:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .UiInputPhone .area-code {
    max-width: 80px; }

.UiLayoutContent {
  padding: calc(var(--spacing) * 2); }

.UiLayoutHeader > header {
  z-index: 200;
  top: 0;
  position: sticky; }

.UiLayoutSidebar {
  --negative-sidebar-width: calc(var(--UiLayoutSidebar-width) * -1); }
  .UiLayoutSidebar > nav {
    background-color: var(--color-background-paper);
    width: var(--UiLayoutSidebar-width);
    height: 100%;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    transform: translateX(var(--negative-sidebar-width));
    transition: transform 200ms; }
  .UiLayoutSidebar > .menu-toggle {
    background-color: var(--color-background-paper);
    color: var(--color-text-primary);
    position: fixed;
    opacity: 0.8;
    top: 0;
    left: 0;
    z-index: 1100;
    transition: transform 200ms, color 200ms, background-color 200ms; }
  .UiLayoutSidebar > section {
    margin-left: 0;
    overflow: auto; }
  .UiLayoutSidebar > .overlay {
    display: none;
    background-color: var(--overlay-background-color);
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0; }
  .UiLayoutSidebar.open > .menu-toggle {
    background-color: var(--color-background-paper);
    color: var(--color-text-primary);
    transform: translateX(var(--UiLayoutSidebar-width));
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    opacity: 1; }
  .UiLayoutSidebar.open > .overlay {
    display: block; }
  .UiLayoutSidebar.open > nav {
    transform: translateX(0); }
  @media (min-width: 1081px) {
    .UiLayoutSidebar > .menu-toggle {
      display: none; }
    .UiLayoutSidebar > section {
      margin-left: var(--UiLayoutSidebar-width); }
    .UiLayoutSidebar > nav {
      background-color: transparent;
      transform: translateX(0);
      top: auto; }
    .UiLayoutSidebar > .overlay {
      display: none !important; } }

.UiTableCellCheckbox {
  --checkbox-size: calc(24px + 16px);
  padding: 0 !important;
  width: var(--checkbox-size);
  height: var(--checkbox-size); }

.UiLayoutSidebarLink {
  display: flex;
  align-items: center;
  color: var(--color-text-primary);
  text-decoration: none;
  padding: 8px 16px;
  transition: color 200ms, opacity 200ms, background-color 200ms, font-weight 200ms;
  font-weight: 500;
  opacity: 0.5;
  cursor: pointer;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px; }
  .UiLayoutSidebarLink > *:not(:first-child) {
    margin-left: 16px; }
  .UiLayoutSidebarLink.active {
    color: var(--color-primary);
    opacity: 1;
    font-weight: bold; }
  .UiLayoutSidebarLink:hover {
    background-color: rgba(127, 127, 127, 0.1);
    opacity: 1; }
